import React, {useEffect, useState, useRef} from "react";
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

import videoImage from '../../assets/screen-preview.svg'

const videoSrc = {
    type: 'video',
    autoplay: true,
    muted: true,
    poster: videoImage,
    sources: [
      {
        src: 'https://public-assets-ts.s3.eu-north-1.amazonaws.com/test-video-1.mp4?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEFYaCmV1LW5vcnRoLTEiRzBFAiEA1VxoVeQ3uwhMh9XPWvHQx9qBlaEmyiPeHl8plkmPPtUCIAEDWTYPR6%2F5%2BwVsQjdbBDU8gTpaxlIa3hK%2F%2BW7lgxsnKu0CCI%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNzMwMzM1MjAwNzU0IgzSj2XGFt2FRvFnVh4qwQK2%2F6o46JK5Ms5adSFMoSvNBJj3TVT8qZSXlzIPoR02Tb8NRCY7T9wyNEothsx17o6dDExyWyOGxzHmS5NjjLCezeyv4BykKFQ3E4bDbwZMqDmmXVoU8tarbOIgj1w4YxnBi7eYXEKAUvRi9%2FcXgpLwD%2FJjoSFCfZbnJvIMaU%2Bqd6UZbCDFYRObh4qf0VTqeId04lo3km590SiXuahsoT%2BrU7ju4ux3mwgFJyDCyXYEheKVDD6jRJW%2BNHcICcmtcOXMeVmek37t5CGerJ8oKopNFCd2lW6H8LK9dg4TQ61WypQSfSdM%2BGZW%2FCWqdCgx%2BTbtDwZq87pfdXLjzb7GeGyQfVsVDXMmnb9CP51eFuQaTs8KgQStER0ngVZ5kXQsi1kLeqzmyjBhQqhLuVbS%2FXAP8x%2BcuoOeKZvplZHcrEo6o2Uw7KK7twY6swIHKF1jbptW4AjgnEq%2BdlW37AKzLWmvH%2BUiMmbI0WoASKHzvNLmkkKVLSFcQ2o0XyW%2Fx1aQxPvi6lU9FjiwOstblmdFY7vqStHPZPONeHJ5QgkSGByd3FRQOIeQ22CQIHZW3Ho%2BVeZ1dLG4dAvMfjp76R3enFBRKf9maBbuTJXMSgXts5Zu32GenAPoMb1Pci7OjIoTcTvig0UvAda9pnWo6821U7XjC7F1wfBDCuqKx3m5GS8I1Fbo3YojNr93dzo%2BR9fcPdhbCg0KQU9Tol52vl9wzLPIvgjeb%2FHzdm9fk%2FYxNJWv0JNBw%2FoR%2FTmIsH400YP90zKkI5IrDElfVDLeyf52IxLjO9H5wB3cN3CnArw9dUhamofDNi2rDalsluo5SD1ijECkm5n7NhZLvh8B5SxV&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240921T143330Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIA2UC26YXZGMTSX2RO%2F20240921%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Signature=6256cf0104353497f8ec75626a2f61654cd30003cd6d808372c522418a696bf9',
        type: 'video/mp4',
        size: 720,
      },
    ],
};


const VideoPlayer = () => {
    return(
        <div className="rounded-2xl overflow-hidden w-[90%]">
            <Plyr source={videoSrc} />
        </div>
    )
}

export default VideoPlayer;